import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import routesConfig from "./routesConfig";
import "./App.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fontsource/noto-sans";
import "@fontsource/noto-sans/400.css";
import "@fontsource/noto-sans/400-italic.css";
import "filepond/dist/filepond.min.css";

const App = () => {
  const [routes, setRoutes] = useState([]);

  useEffect(() => {
    const loadRoutes = async () => {
      const loadedRoutes = await Promise.all(
        routesConfig.map(async (route) => {
          try {
            const Component = React.lazy(() => import(`./Pages/${route.component}`));
            return { ...route, Component };
          } catch (error) {
            console.error(`Error loading component for path: ${route.path}`, error);
            return { ...route, Component: () => <div>Component not found</div> };
          }
        })
      );
      setRoutes(loadedRoutes);
    };

    loadRoutes();
  }, []);

  return (
    <div className="app-container">
      <React.Suspense fallback={<div>Loading...</div>}>
        <Routes>
          {routes.map(({ path, Component }) => (
            <Route key={path} path={path} element={<Component />} />
          ))}
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default App;
