const routesConfig = [

  // Home
  { path: "/", component: "Home/Home" },

  // Listings
  { path: "/listings", component: "Listings/Listing" },
  { path: "/listings/used", component: "Listings/Listing", filter: "used" },
  { path: "/listings/new", component: "Listings/Listing", filter: "new" },
  { path: "/listings/electric", component: "Listings/Listing", filter: "electric" },
  { path: "/listings/details/:id", component: "Listings/ListingDetail" },
  { path: "/dealers/:dealerName/cars", component: "Listings/Listing" },

  // Car News
  { path: "/car-news", component: "CarNews/CarNews" },
  { path: "/car-news/detail/:id", component: "CarNews/CarNewsDetail" },

  // Dealers
  { path: "/dealers", component: "Dealers/Dealers" },
  { path: "/dealers/details/:id", component: "Dealers/DealerDetail" },

  //Sell Car
  { path: "/sell-car", component: "SellCar/SellCar" },

  //Footer Links
  { path: "/faqs", component: "FAQs" },
  { path: "/dealership-partners", component: "DealershipPartnersPage" },
  { path: "/about-us", component: "AboutUs" },
  { path: "/contact-us", component: "ContactUs" },
  { path: "/privacy-policy", component: "PrivacyPolicy" },
  { path: "/gdpr", component: "GDPR" },
  { path: "/fraud-disclaimer", component: "FraudDisclaimer" },
  { path: "/advertise", component: "Advertise" },

  //Car Saved
  { path: "/carsaved", component: "CarSaved/CarSaved" },

  { path: "/login", component: "Login" },
  { path: "/registration", component: "Registration" },
  { path: "/forgot-password", component: "ForgotPassword" },
  { path: "/dashboard", component: "Dashboard/Dashboard" },
  { path: "/dashboard/listing", component: "Dashboard/Listing/Listing" },
  { path: "/dashboard/listing/add", component: "Dashboard/Listing/Add" },
  { path: "/dashboard/listing/detail/:id", component: "Dashboard/Listing/Detail" },
  { path: "/dashboard/listing/preview/:id", component: "Dashboard/Listing/Preview" },
  { path: "/dashboard/test-drives", component: "Dashboard/TestDrive" },
  { path: "/dashboard/messages", component: "Dashboard/Messages" },
  { path: "/dashboard/settings/dealer", component: "Dashboard/Settings/DealerInfo" },
  { path: "/dashboard/settings/profile", component: "Dashboard/Settings/ProfileAccount" },
  { path: "/dashboard/settings/notification", component: "Dashboard/Settings/Notification" },
  { path: "/dashboard/settings/security", component: "Dashboard/Settings/Security" },
  { path: "/dashboard/settings/billing", component: "Dashboard/Settings/Billing" },
  { path: "/dashboard/bulkupload/bulk", component: "Dashboard/BulkUpload/Bulk" },
  { path: "/dashboard/bulkupload/xml", component: "Dashboard/BulkUpload/XMLViewer" },
  { path: "/dashboard/bulkupload/manualupload", component: "Dashboard/BulkUpload/ManualUploadAdd" },
];

export default routesConfig;